.subtitle {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
    font-weight: bold;
    margin-top: 3rem;
}

.body {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
}

.link {
    color: #3030f1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

.link > a {
    color: #3030f1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
}

.title {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
    font-weight: bold;
}

.bodyTitle {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
    font-weight: bold;
    margin: 1rem 0;
}

.info {
    font-size: 14px !important;
    font-weight: bold !important;
    font-family: "Arial" !important;
    color: #595959 !important;
}

.list > li {
    margin: 1rem 0;
}
