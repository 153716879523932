ul.password-rules {
  list-style-type: none;
}

.position-relative {
  position: relative;
}
.password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  transform: translateY(-50%);
}

.password-icon {
  font-size: 1.2rem;
  color: #aaa;
  transition: color 0.3s ease;
}

.form-control {
  padding-right: 35px;
}

.form-floating > label {
  transition: transform 0.3s ease, font-size 0.3s ease;
  pointer-events: none;
}

.form-control:focus ~ label {
  transform: translateY(-1.5rem);
  font-size: 0.75rem;
}
