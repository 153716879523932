.sign {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.content {
  width: 40% !important;
  margin: auto;
}

@media (max-width: 768px) {
  .content {
    width: 60%;
  }
}

@media (max-width: 468px) {
  .content {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .mb-5 {
    width: 10%;
  }
}
