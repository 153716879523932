.fields-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}

.field {
  flex: 1;
}

.field label {
  display: block;
  margin-bottom: 5px;
}

.field select,
.field input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

h3 {
  text-align: center;
}
h4 {
  text-align: center;
}

.main-content {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.awardimg {
  /* flex: 1; Flex grow to make it take available space */
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.awardimg img {
  max-width: 50%; /* Image responsive */
  height: auto;
}

/* .awarddes h4 {
  font-size: 24px;
  margin-bottom: 10px;
} */
.awarddes textarea {
  display: flex;
  width: 100%;
  /* padding: 10px 20px; */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  justify-content: left;
  align-items: center;
  text-align: center;
  margin-right: 100px;
}
