.button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.left-button,
.right-button {
  padding: 10px;
  font-size: 16px;
}

.left-button {
  text-align: right;
  margin-right: 20px;
  padding-right: 20px;
}
