.detail-link {
  color: var(--bs-link-color);
  text-decoration: underline;
}

.row {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
}

.pin-image {
  height: 150px;
  object-fit: contain;
}

.pins-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-filter {
  width: 50% !important;
}
