.img {
  width: 30px;
  height: 30px;
}
.active-navlink{
  background: #ff8b3d !important;
  color: white !important;
  border-radius: 2rem !important;
  display: inline block !important;
}

@media(max-width: 768px){
  .navbar-nav{
    align-items: center;
  }
}