.lst-kix_9845l8mx3zue-8 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-8, lower-roman) ". ";
}

.lst-kix_9845l8mx3zue-7 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-7, lower-latin) ". ";
}

ol.lst-kix_9845l8mx3zue-7.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-7 0;
}

ol.lst-kix_eoy0n7j6kv9p-8.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-8 0;
}

.lst-kix_eoy0n7j6kv9p-2 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-2;
}

.lst-kix_9845l8mx3zue-1 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-1, lower-latin) ". ";
}

.lst-kix_axdv9fo5szb-0 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-0;
}

.lst-kix_9845l8mx3zue-0 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-0, lower-latin) ". ";
}

.lst-kix_9845l8mx3zue-0 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-0;
}

ol.lst-kix_axdv9fo5szb-4.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-4 0;
}

.lst-kix_mzi6n8706ud8-1 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-1;
}

.lst-kix_8imvo4wicjl-1 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-1;
}

ol.lst-kix_mzi6n8706ud8-5.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-5 0;
}

.lst-kix_11gi6o3ajhf3-2 > li:before {
    content: "\0025a0   ";
}

ul.lst-kix_ezf85sq9uwwk-4 {
    list-style-type: none;
}

ul.lst-kix_ezf85sq9uwwk-5 {
    list-style-type: none;
}

ul.lst-kix_ezf85sq9uwwk-2 {
    list-style-type: none;
}

ul.lst-kix_ezf85sq9uwwk-3 {
    list-style-type: none;
}

.lst-kix_11gi6o3ajhf3-0 > li:before {
    content: "\0025cf   ";
}

.lst-kix_11gi6o3ajhf3-4 > li:before {
    content: "\0025cb   ";
}

ul.lst-kix_ezf85sq9uwwk-0 {
    list-style-type: none;
}

ul.lst-kix_ezf85sq9uwwk-1 {
    list-style-type: none;
}

.lst-kix_eoy0n7j6kv9p-4 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-4;
}

.lst-kix_11gi6o3ajhf3-1 > li:before {
    content: "\0025cb   ";
}

.lst-kix_11gi6o3ajhf3-5 > li:before {
    content: "\0025a0   ";
}

.lst-kix_mzi6n8706ud8-0 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-0, decimal) ". ";
}

ul.lst-kix_ezf85sq9uwwk-8 {
    list-style-type: none;
}

.lst-kix_mzi6n8706ud8-1 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-1, lower-latin) ". ";
}

.lst-kix_11gi6o3ajhf3-3 > li:before {
    content: "\0025cf   ";
}

ul.lst-kix_ezf85sq9uwwk-6 {
    list-style-type: none;
}

ul.lst-kix_ezf85sq9uwwk-7 {
    list-style-type: none;
}

.lst-kix_mzi6n8706ud8-3 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-3, decimal) ". ";
}

.lst-kix_mzi6n8706ud8-2 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-2, lower-roman) ". ";
}

.lst-kix_mzi6n8706ud8-4 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-4, lower-latin) ". ";
}

.lst-kix_9845l8mx3zue-2 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-2;
}

.lst-kix_11gi6o3ajhf3-8 > li:before {
    content: "\0025a0   ";
}

.lst-kix_8imvo4wicjl-3 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-3;
}

.lst-kix_11gi6o3ajhf3-6 > li:before {
    content: "\0025cf   ";
}

.lst-kix_mzi6n8706ud8-7 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-7, lower-latin) ". ";
}

.lst-kix_mzi6n8706ud8-6 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-6, decimal) ". ";
}

.lst-kix_mzi6n8706ud8-5 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-5, lower-roman) ". ";
}

.lst-kix_11gi6o3ajhf3-7 > li:before {
    content: "\0025cb   ";
}

ol.lst-kix_mzi6n8706ud8-6.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-6 0;
}

ol.lst-kix_axdv9fo5szb-5.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-5 0;
}

.lst-kix_9845l8mx3zue-4 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-4;
}

.lst-kix_eoy0n7j6kv9p-6 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-6, decimal) ". ";
}

.lst-kix_eoy0n7j6kv9p-8 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-8, lower-roman) ". ";
}

.lst-kix_eoy0n7j6kv9p-5 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-5, lower-roman) ". ";
}

.lst-kix_eoy0n7j6kv9p-0 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-0;
}

.lst-kix_eoy0n7j6kv9p-2 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-2, lower-roman) ". ";
}

.lst-kix_eoy0n7j6kv9p-4 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-4, lower-latin) ". ";
}

.lst-kix_eoy0n7j6kv9p-3 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-3, decimal) ". ";
}

ol.lst-kix_8imvo4wicjl-3.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-3 0;
}

.lst-kix_axdv9fo5szb-4 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-4;
}

.lst-kix_eoy0n7j6kv9p-6 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-6;
}

ol.lst-kix_9845l8mx3zue-1.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-1 0;
}

.lst-kix_eoy0n7j6kv9p-7 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-7, lower-latin) ". ";
}

ol.lst-kix_mzi6n8706ud8-0.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-0 0;
}

.lst-kix_mzi6n8706ud8-3 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-3;
}

ol.lst-kix_9845l8mx3zue-8.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-8 0;
}

.lst-kix_9845l8mx3zue-2 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-2, lower-roman) ". ";
}

ol.lst-kix_9845l8mx3zue-2.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-2 0;
}

ol.lst-kix_8imvo4wicjl-4.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-4 0;
}

.lst-kix_9845l8mx3zue-3 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-3, decimal) ". ";
}

.lst-kix_9845l8mx3zue-4 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-4, lower-latin) ". ";
}

.lst-kix_9845l8mx3zue-6 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-6, decimal) ". ";
}

.lst-kix_9845l8mx3zue-5 > li:before {
    content: "" counter(lst-ctn-kix_9845l8mx3zue-5, lower-roman) ". ";
}

.lst-kix_9845l8mx3zue-7 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-7;
}

.lst-kix_mzi6n8706ud8-8 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-8;
}

ol.lst-kix_8imvo4wicjl-5.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-5 0;
}

ol.lst-kix_9845l8mx3zue-3.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-3 0;
}

ol.lst-kix_8imvo4wicjl-0 {
    list-style-type: none;
}

.lst-kix_mzi6n8706ud8-7 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-7;
}

ol.lst-kix_8imvo4wicjl-2 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-1 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-4 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-3 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-8.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-8 0;
}

ol.lst-kix_8imvo4wicjl-6 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-5 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-8 {
    list-style-type: none;
}

ol.lst-kix_8imvo4wicjl-7 {
    list-style-type: none;
}

.lst-kix_axdv9fo5szb-7 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-7;
}

.lst-kix_eoy0n7j6kv9p-7 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-7;
}

ul.lst-kix_11gi6o3ajhf3-8 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-7 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-6 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-6.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-6 0;
}

ul.lst-kix_11gi6o3ajhf3-5 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-4.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-4 0;
}

ul.lst-kix_11gi6o3ajhf3-0 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-4 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-3 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-2 {
    list-style-type: none;
}

ul.lst-kix_11gi6o3ajhf3-1 {
    list-style-type: none;
}

.lst-kix_eoy0n7j6kv9p-0 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-0, decimal) ". ";
}

ol.lst-kix_mzi6n8706ud8-4.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-4 0;
}

ol.lst-kix_mzi6n8706ud8-1.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-1 0;
}

.lst-kix_8imvo4wicjl-6 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-6;
}

ol.lst-kix_eoy0n7j6kv9p-1.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-1 0;
}

.lst-kix_9845l8mx3zue-5 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-5;
}

ol.lst-kix_axdv9fo5szb-8.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-8 0;
}

.lst-kix_mzi6n8706ud8-8 > li:before {
    content: "" counter(lst-ctn-kix_mzi6n8706ud8-8, lower-roman) ". ";
}

ol.lst-kix_eoy0n7j6kv9p-3.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-3 0;
}

.lst-kix_8imvo4wicjl-5 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-5;
}

ol.lst-kix_mzi6n8706ud8-3.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-3 0;
}

ol.lst-kix_mzi6n8706ud8-2.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-2 0;
}

.lst-kix_axdv9fo5szb-2 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-2;
}

ol.lst-kix_eoy0n7j6kv9p-2.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-2 0;
}

.lst-kix_9845l8mx3zue-6 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-6;
}

.lst-kix_axdv9fo5szb-8 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-8;
}

.lst-kix_eoy0n7j6kv9p-8 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-8;
}

ol.lst-kix_9845l8mx3zue-5.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-5 0;
}

ol.lst-kix_8imvo4wicjl-7.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-7 0;
}

ol.lst-kix_mzi6n8706ud8-7 {
    list-style-type: none;
}

ol.lst-kix_mzi6n8706ud8-8 {
    list-style-type: none;
}

.lst-kix_8imvo4wicjl-2 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-2;
}

.lst-kix_ezf85sq9uwwk-7 > li:before {
    content: "\0025cb   ";
}

.lst-kix_8imvo4wicjl-7 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-7, lower-latin) ". ";
}

.lst-kix_8imvo4wicjl-8 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-8, lower-roman) ". ";
}

.lst-kix_ezf85sq9uwwk-4 > li:before {
    content: "\0025cb   ";
}

.lst-kix_ezf85sq9uwwk-8 > li:before {
    content: "\0025a0   ";
}

.lst-kix_ezf85sq9uwwk-5 > li:before {
    content: "\0025a0   ";
}

.lst-kix_mzi6n8706ud8-2 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-2;
}

.lst-kix_9845l8mx3zue-1 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-1;
}

.lst-kix_ezf85sq9uwwk-6 > li:before {
    content: "\0025cf   ";
}

.lst-kix_ezf85sq9uwwk-0 > li:before {
    content: "\0025cf   ";
}

.lst-kix_8imvo4wicjl-1 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-1, lower-latin) ". ";
}

.lst-kix_ezf85sq9uwwk-1 > li:before {
    content: "\0025cf   ";
}

.lst-kix_8imvo4wicjl-0 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-0, lower-latin) ". ";
}

ol.lst-kix_mzi6n8706ud8-8.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-8 0;
}

ol.lst-kix_axdv9fo5szb-7.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-7 0;
}

.lst-kix_eoy0n7j6kv9p-3 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-3;
}

ol.lst-kix_eoy0n7j6kv9p-5.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-5 0;
}

ol.lst-kix_mzi6n8706ud8-0 {
    list-style-type: none;
}

ol.lst-kix_mzi6n8706ud8-1 {
    list-style-type: none;
}

.lst-kix_axdv9fo5szb-1 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-1;
}

.lst-kix_ezf85sq9uwwk-3 > li:before {
    content: "\0025cf   ";
}

ol.lst-kix_mzi6n8706ud8-2 {
    list-style-type: none;
}

.lst-kix_ezf85sq9uwwk-2 > li:before {
    content: "\0025a0   ";
}

ol.lst-kix_mzi6n8706ud8-3 {
    list-style-type: none;
}

ol.lst-kix_mzi6n8706ud8-4 {
    list-style-type: none;
}

ol.lst-kix_mzi6n8706ud8-5 {
    list-style-type: none;
}

ol.lst-kix_mzi6n8706ud8-6 {
    list-style-type: none;
}

.lst-kix_axdv9fo5szb-0 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-0, upper-roman) ". ";
}

ol.lst-kix_8imvo4wicjl-6.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-6 0;
}

ol.lst-kix_9845l8mx3zue-4.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-4 0;
}

.lst-kix_8imvo4wicjl-0 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-0;
}

ol.lst-kix_axdv9fo5szb-1.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-1 0;
}

.lst-kix_axdv9fo5szb-6 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-6, decimal) ". ";
}

.lst-kix_8imvo4wicjl-2 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-2, lower-roman) ". ";
}

.lst-kix_axdv9fo5szb-3 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-3;
}

.lst-kix_axdv9fo5szb-5 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-5, lower-roman) ". ";
}

.lst-kix_axdv9fo5szb-4 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-4, lower-latin) ". ";
}

.lst-kix_8imvo4wicjl-3 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-3, decimal) ". ";
}

.lst-kix_8imvo4wicjl-4 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-4, lower-latin) ". ";
}

.lst-kix_axdv9fo5szb-2 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-2, lower-roman) ". ";
}

.lst-kix_8imvo4wicjl-5 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-5, lower-roman) ". ";
}

.lst-kix_8imvo4wicjl-6 > li:before {
    content: "" counter(lst-ctn-kix_8imvo4wicjl-6, decimal) ". ";
}

.lst-kix_axdv9fo5szb-1 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-1, lower-latin) ". ";
}

.lst-kix_axdv9fo5szb-3 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-3, decimal) ". ";
}

.lst-kix_eoy0n7j6kv9p-1 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-1;
}

ol.lst-kix_eoy0n7j6kv9p-0.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-0 0;
}

.lst-kix_axdv9fo5szb-7 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-7, lower-latin) ". ";
}

.lst-kix_8imvo4wicjl-4 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-4;
}

.lst-kix_axdv9fo5szb-8 > li:before {
    content: "" counter(lst-ctn-kix_axdv9fo5szb-8, lower-roman) ". ";
}

.lst-kix_mzi6n8706ud8-0 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-0;
}

ol.lst-kix_axdv9fo5szb-6.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-6 0;
}

ol.lst-kix_eoy0n7j6kv9p-7 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-6.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-6 0;
}

ol.lst-kix_eoy0n7j6kv9p-8 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-0 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-1 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-2 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-3 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-4 {
    list-style-type: none;
}

.lst-kix_mzi6n8706ud8-6 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-6;
}

ol.lst-kix_eoy0n7j6kv9p-5 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-6 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-6 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-7 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-4 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-5 {
    list-style-type: none;
}

.lst-kix_8imvo4wicjl-8 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-8;
}

ol.lst-kix_axdv9fo5szb-0.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-0 0;
}

ol.lst-kix_9845l8mx3zue-0.start {
    counter-reset: lst-ctn-kix_9845l8mx3zue-0 0;
}

ol.lst-kix_axdv9fo5szb-8 {
    list-style-type: none;
}

.lst-kix_8imvo4wicjl-7 > li {
    counter-increment: lst-ctn-kix_8imvo4wicjl-7;
}

ol.lst-kix_axdv9fo5szb-2 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-3 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-0 {
    list-style-type: none;
}

ol.lst-kix_axdv9fo5szb-1 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-6 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-5 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-8 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-7 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-2 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-1 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-4 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-3 {
    list-style-type: none;
}

ol.lst-kix_9845l8mx3zue-0 {
    list-style-type: none;
}

ol.lst-kix_eoy0n7j6kv9p-7.start {
    counter-reset: lst-ctn-kix_eoy0n7j6kv9p-7 0;
}

ol.lst-kix_mzi6n8706ud8-7.start {
    counter-reset: lst-ctn-kix_mzi6n8706ud8-7 0;
}

.lst-kix_9845l8mx3zue-8 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-8;
}

ol.lst-kix_8imvo4wicjl-2.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-2 0;
}

.lst-kix_axdv9fo5szb-6 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-6;
}

.lst-kix_eoy0n7j6kv9p-1 > li:before {
    content: "" counter(lst-ctn-kix_eoy0n7j6kv9p-1, lower-latin) ". ";
}

ol.lst-kix_8imvo4wicjl-0.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-0 0;
}

.lst-kix_eoy0n7j6kv9p-5 > li {
    counter-increment: lst-ctn-kix_eoy0n7j6kv9p-5;
}

.lst-kix_mzi6n8706ud8-5 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-5;
}

.lst-kix_mzi6n8706ud8-4 > li {
    counter-increment: lst-ctn-kix_mzi6n8706ud8-4;
}

ol.lst-kix_axdv9fo5szb-2.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-2 0;
}

.lst-kix_axdv9fo5szb-5 > li {
    counter-increment: lst-ctn-kix_axdv9fo5szb-5;
}

.lst-kix_9845l8mx3zue-3 > li {
    counter-increment: lst-ctn-kix_9845l8mx3zue-3;
}

ol.lst-kix_8imvo4wicjl-1.start {
    counter-reset: lst-ctn-kix_8imvo4wicjl-1 0;
}

li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
}

ol.lst-kix_axdv9fo5szb-3.start {
    counter-reset: lst-ctn-kix_axdv9fo5szb-3 0;
}

ol {
    margin: 0;
    padding: 0;
}

table td,
table th {
    padding: 0;
}

.c0 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c18 {
    background-color: #ffffff;
    padding-top: 18pt;
    padding-bottom: 11pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c10 {
    margin-left: 72pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c5 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 23pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c16 {
    background-color: #ffffff;
    padding-top: 20pt;
    padding-bottom: 11pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c13 {
    padding-top: 18pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c9 {
    padding-top: 20pt;
    padding-bottom: 6pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c12 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11.5pt;
    font-family: "Arial";
    font-style: normal;
}

.c7 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 11pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Arial";
    font-style: normal;
}

.c6 {
    background-color: #ffffff;
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
}

.c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 20pt;
    font-family: "Arial";
    font-style: normal;
}

.c1 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Arial";
    font-style: normal;
}

.c19 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c38 {
    padding-top: 0pt;
    padding-bottom: 3pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c17 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
}

.c22 {
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c26 {
    padding-top: 12pt;
    padding-bottom: 23pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.c14 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
}

.c32 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
}

.c34 {
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal;
}

.c30 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    text-decoration: underline;
}

.c28 {
    padding: 0;
    margin: 0;
}

.c24 {
    color: #a30003;
    font-weight: 700;
}

.c33 {
    margin-left: 36pt;
    padding-left: 0pt;
}

.c20 {
    background-color: #ffffff;
    font-weight: 700;
}

.c23 {
    max-width: 90%;
    padding: 72pt 72pt 72pt 72pt;
}

.c21 {
    background-color: #ffffff;
}

.c36 {
    page-break-after: avoid;
}

.c25 {
    font-weight: 700;
}

.c37 {
    font-size: 20pt;
}

.c29 {
    color: #a30003;
}

.c8 {
    height: 11pt;
}

.c31 {
    background-color: #ff0000;
}

.c35 {
    margin-left: 36pt;
}

.c11 {
    color: #980000;
}

.c4 {
    font-size: 10.5pt;
}

.c15 {
    font-size: 26pt;
}

.c27 {
    font-size: 11.5pt;
}

.title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

.subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

li {
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Arial";
}

h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
}

h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: "Arial";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
}
